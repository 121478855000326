import { createRouter, createWebHistory } from 'vue-router'
import { SessionUtil } from '@/utils/SessionUtil.js'
import { ValdUtil } from '@/utils/ValdUtil.js'

const requireRoute = require.context('./routes', false, /\.js$/) // Get js files inside routes folder
const routes = []

requireRoute.keys().forEach(fileName => {
    // Avoid the index.js file
    if (fileName === './index.js') {
        return;
    }

    const route = requireRoute(fileName).default
    routes.push(...route)
})

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: 'history',
    // historyApiFallback: true,
    base: process.env.BASE_URL,
    routes: [...routes]
})


router.beforeEach(function(to, from, next) {
    if((ValdUtil.isNull(from.name)) && to.name != 'Login'){
        if(ValdUtil.isNotNull(SessionUtil.getToken())){
            if(to.name != 'Home'){
                next({name:'Home'});
            }else{
                next();
            }
        }else{
            if(to.name == 'Home'){
                next({name:'Login'});
            }else{
                next();
            }
        }
    }else{
        next();
    }
});

export default router
