<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
import '@/static/css/bootstrap.css'
import '@/static/css/style.css'
import '@/static/css/index.css'
import '@/static/css/web.css'
import "@/assets/css/common.css"
import "@/assets/css/content/common_content.css"
import "@/assets/css/content/jqGrid_mod.css"
import "@/assets/css/login.css"
import '@/static/css/plugins/jqGrid/ui.jqgrid.css'

export default {
    data: function() {
        return {

        }
    },
    methods: {
        init: function() {
          
        },
    },
    computed: {

    },
    mounted() {
        this.init();
    }
}
</script>
